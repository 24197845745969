<template>
    <div>
        <table class="table table-striped">
            <thead>
                <tr class="text-center">
                    <th>
                        နံပါတ်
                        <font-awesome-icon class="text-danger" :icon="['fas', 'fa-minus-circle']"></font-awesome-icon>

                    </th>
                    <th>
                        ငွေ
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(num, index) in nums" :key="index" class="text-center">
                    <td>{{ num.number }}</td>
                    <td>{{ num.total_amount ?? 0 }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    mounted() {
        this.getHotNumbers()
    },
    data() {
        return {
            nums: []
        }
    },
    methods: {
        getHotNumbers() {
            userService.getBlockNumbers(this.$route.params.section).then(({ data }) => {
                this.nums = data
            })
        }
    }
}
</script>