<template>
    <div class="p-2 d-flex  align-items-center">
        <form @submit="handleLogin()" class="w-100 my-auto border p-4 justify-content-between">
            <h1 class="title mb-3 text-center">Welcome to 123 2D</h1>
            <p class="subtitle mb-4 text-center">အကောင့်ဝင်ရန်</p>

            <div>
                <FormGroup v-model="phone" :errors="errors.phone" label="Username" type="text" class="mb-4">
                </FormGroup>
                <FormGroup v-model="password" :errors="errors.password" label="Password" type="password" class="mb-4">
                </FormGroup>
            </div>

            <PrimaryButton type="submit" class="login-btn mt-auto">Log in</PrimaryButton>
        </form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            phone: "",
            password: "",
            errors: {
                phone: []
            }
        }
    },
    created() {
        if (this.$store.state.auth.status.loggedIn) {
            this.$router.push({ name: 'home' })
        }
    },
    methods: {
        handleLogin() {
            this.$store.dispatch('loading')
            event.preventDefault()
            this.$store.dispatch('auth/login', {
                phone: this.phone,
                password: this.password
            }).then((data) => {
                console.log(data)
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', 'Login Successful!')
                    this.$router.push({ name: 'home' })
                    location.reload()
                }
            }).catch(({ response }) => {
                console.log(response)
                if (response.status == 422) {
                    this.errors = response.data.errors
                }
            })
        }
    }
}
</script>
<style></style>
