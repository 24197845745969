export default {
    formatValue(value) {
        var number, amount, total_amount, numbers;
        if (value.includes('R')) {
            let pattern = new RegExp('^[0-9]{2}[R][2]?[0-9]{2,}$');
            if(pattern.test(value) && value[0] != value[1]) {
                    number = value[0] + value[1] + 'R';
                    amount = parseInt(value.split('R')[1]);
                    numbers = this.getNumbers(number, amount);
                    total_amount = amount * 2;
                } else {
                    let rPos = value.indexOf('R')
                    let firstNumAmount = parseInt(value.substring(2, rPos));
                    let secondNumAmount = parseInt(value.substring(rPos + 1, value.length ));
                    number = value.substring(0, rPos + 1);
                    amount = secondNumAmount;
                 total_amount = amount + firstNumAmount
                }

        }
        else if (value.includes('APP')) {
            let pattern = new RegExp(/^(\d)(?!\1+$)\d*APP\d+$/);
            if (pattern.test(value)) {
                number = value.split('APP')[0] ;
                amount = parseInt(value.split('APP')[1]);
                let total_numbers = number.length * (number.length - 1);
                total_amount = amount * total_numbers
                number += 'APP';
            }
        }
        else if (value.includes('AP')) {
            let pattern = new RegExp(/^(\d)(?!\1+$)\d*AP\d+$/);
            if (pattern.test(value)) {
                number = value.split('AP')[0] ;
                amount = parseInt(value.split('AP')[1]);
                let total_numbers = number.length * number.length;
                total_amount = amount * total_numbers
                number += 'AP';
            }
        }
            
        else if (value.includes('SP')) {
            let pattern = new RegExp('^SP[0-9]+$');
            if (pattern.test(value)) {
                number = 'SP';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 5;
            }
        }else if (value.includes('+')) {
            let pattern = new RegExp('^[0-9][+][2]?[0-9]{2,}$');
            if (pattern.test(value)) {
                number = parseInt(value[0]) + value[1];
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 19;
            }
        }
        else if (value.includes('W')) {
            let pattern = new RegExp('^W[0-9]+$');
            if (pattern.test(value)) {
                number = 'W';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 10;
            }
        }
        else if (value.includes('NK')) {
            let pattern = new RegExp('^NK[0-9]+$');
            if (pattern.test(value)) {
                number = 'NK';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 20;
            }
        }
        else if (value.includes('*')) {
            let behind = new RegExp('^[0-9]\\*\\d+$');
            let front = new RegExp('^\\*[0-9]+$');
            if (front.test(value)) {
                number = '*' + parseInt(value[1]);
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 10;
            }

            if (behind.test(value)) {
                number = parseInt(value[0]) + '*';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 10;
            }
        }
        
        else if (value.includes('SS')) {
            let pattern = new RegExp('^SS[0-9]+$');
            if (pattern.test(value)) {
                number = 'SS';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 25;
            }
        }
        else if (value.includes('MM')) {
            let pattern = new RegExp('^MM[0-9]+$');
            if (pattern.test(value)) {
                number = 'MM';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 25;

            }
        }
        else if (value.includes('MS')) {
            let pattern = new RegExp('^MS[0-9]+$');
            if (pattern.test(value)) {
                number = 'MS';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 25;

            }
        }
        else if (value.includes('SM')) {
            let pattern = new RegExp('^SM[0-9]+$');
            if (pattern.test(value)) {
                number = 'SM';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 25;

            }
        }
        else if (value.includes('MP')) {
            let pattern = new RegExp('^MP[0-9]+$');
            if (pattern.test(value)) {
                number = 'MP';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 5;
            }
        }
        else if (value.includes('S')) {
            let pattern = new RegExp('^[0-9][S][2]?[0-9]{2,}$');
            if (pattern.test(value)) {
                number = parseInt(value[0]) + value[1];
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * this.getNumbers(number,amount).length;
            }
        }
            
        else if (value.includes('P')) {
            let pattern = new RegExp('^P[0-9]+$');
            if (pattern.test(value)) {
                number = 'P';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 10;
            }
        } 
            
        else if (value.includes('K')) {
            let pattern = new RegExp('^K[0-9]+$');
            if (pattern.test(value)) {
                number = 'K';
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * 10;
            }
        }
        else if (value.includes('M')) {
            let pattern = new RegExp('^[0-9][M][2]?[0-9]{2,}$');
            if (pattern.test(value)) {
                number = parseInt(value[0]) + value[1];
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * this.getNumbers(number,amount).length;
            }
        }
        else if (value.includes('B')) {
            let pattern = new RegExp('^[0-9][B][2]?[0-9]{2,}$');
            if (pattern.test(value)) {
                number = value.substring(0, 2);
                amount = parseInt(value.split(number)[1]);
                total_amount = amount * this.getNumbers(number, amount).length;
                console.log(this.getNumbers(number,amount))
            }
        }
        else {
            let pattern = new RegExp(/^\d+$/);
            if (pattern.test(value)) {
                number = value[0] + value[1];
                amount = parseInt(value.toString().substring(2,value.length));
                total_amount = amount 
            }
        }


        if (number && amount && total_amount) {
            numbers = this.getNumbers(number, amount)
            return {
                number, amount, total_amount, numbers
            };
        } else {
            return false;
        }

    },
    getNumbers(number, amount) {
        let data = [];
            if (number.includes('R')) {
                let rPos = number.indexOf('R')
                let num = number
                if (rPos == 2) {
                    let rNum = number[1] + number[0];
                    let num = number[0] + number[1];
                    let nums = [num, rNum];
                    nums.forEach(element => {
                        data.push({
                            number: element,
                            amount: amount,
                        })
                    });
                } else {
                    let firstNum = num[0] + '' + num[1];
                    let firstNumAmount = parseInt(num.substring(2, rPos));
                    data.push({
                        number: firstNum,
                        amount: firstNumAmount
                     })
                    
                    let secondNum = firstNum[1] + '' + firstNum[0];
                    let secondNumAmount = amount;
                    data.push({
                        number: secondNum,
                        amount: secondNumAmount
                    })
               }
            }
            else if (number.includes("APP")) {
                let numbers = number.split('APP')[0].split('')
                 const regex = new RegExp(`[${numbers}]{2}`);
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (regex.test(number) && number[0] != number[1]) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if (number.includes("AP")) {
                let numbers = number.split('AP')[0].split('')
                 const regex = new RegExp(`[${numbers}]{2}`);
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (regex.test(number)) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("NK")) {
                ['01', '10', '12', '21', '23', '32', '34', '43', '45', '54', '56', '65', '67', '76', '78', '87', '89', '98', '90', '09']
.forEach(number => {
                    data.push({
                        number: number,
                        amount: amount
                    })
                })
            }
            else if(number.includes("W")) {
                let nums = ['05', '16', '27', '38', '49', '50', '61', '72', '83', '94'];
                nums.forEach(element => {
                    data.push({
                        number: element,
                        amount: amount,
                    })
                });
            }
            else if (number.includes("+")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');
                    let findNum = number[0];
                    if (num.toString().includes(findNum)) {
                        data.push({
                            number: num,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SS")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 == 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("MM")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 != 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SM")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 == 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("SP")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] == number[1] && number[0] % 2 == 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("MP")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] == number[1] && number[0] % 2 != 0 && number[1] % 2 != 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
                
            else if(number.includes("MS")) {
                for (let i = 0; i <= 99; i++) {
                    let number = i.toString().padStart(2, '0');
                    if (number[0] % 2 != 0 && number[1] % 2 == 0) {
                        data.push({
                            number: number,
                            amount: amount
                        })
                    }
                }
            }
            else if(number.includes("M")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');

                    let numPos = num.indexOf(number[0]);

                    if (numPos != -1) {
                        if (numPos == 0 && num[1] % 2 != 0 ) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }

                        if (numPos == 1 && num[0] % 2 != 0 ) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }
                    }

                    
                }
            }
            else if(number.includes("S")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');

                    let numPos = num.indexOf(number[0]);

                    if (numPos != -1) {
                        if (numPos == 0 && num[1] % 2 == 0 ) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }

                        if (numPos == 1 && num[0] % 2 == 0 ) {
                            data.push({
                                number: num,
                                amount: amount
                            })
                        }
                    }

                    
                }
            }
            else if(number.includes("K")) {
                ['18','81','70','07','42','24','69','96','53','35'].forEach(number => {
                    data.push({
                        number: number,
                        amount: amount
                    })
                })
            }
                
            else if(number.includes("P")) {
                let nums = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99'];
                nums.forEach(element => {
                    data.push({
                        number: element,
                        amount: amount,
                    })
                });
            }
            else if(number.includes("B")) {
                for (let i = 0; i <= 99; i++) {
                    let num = i.toString().padStart(2, '0');
                    let sum = parseInt(num[0]) + parseInt(num[1])
                    if (sum.toString()[sum.toString().length - 1]  == number[0]) {
                        data.push({
                            number: num,
                            amount: amount
                        })
                    }
                }
            }
            else if (number.includes("*")) {
                if (number.toString().indexOf('*') == 0) {
                    let num = number[1];
                    for (let i = 0; i <= 99; i++) {
                        let number = i.toString().padStart(2, '0');
                        if (number[1] == num) {
                            data.push({
                                number: number,
                                amount: amount
                            })
                        }
                    }
                }

                if (number.toString().indexOf('*') == 1) {
                    let num = number[0];
                    for (let i = 0; i <= 99; i++) {
                        let number = i.toString().padStart(2, '0');
                        if (number[0] == num) {
                            data.push({
                                number: number,
                                amount: amount
                            })
                        }
                    }
                }
                
            }

        return data;
    },

    getMMName(value) {
        if(value.includes('APP')) {
            return value.replace('APP', ' (အပြီးရှင်းအပူးမပါ)');
        }
        else if (value.includes('AP')) {
            return value.replace('AP', ' (အပြီးရှင်း)');
        }
        else if (value.includes('NK')) {
            return 'ညီကို';
        }
        else if (value.includes('SS')) {
            return 'စုံစုံ';
        }
        else if (value.includes('MM')) {
            return 'မမ';
        }
        else if (value.includes('MS')) {
           return 'မစုံ';
        }
        else if (value.includes('SM')) {
           return 'စုံမ';
        }
        else if (value.includes('SP')) {
            return "စုံပူး";
        }
        else if (value.includes('MP')) {
            return "မပူး";
        }
        else if (value.includes('S')) {
            return value[0] + "စုံကပ်";
        }
        else if (value.includes('M')) {
            return value[0] + "မကပ်";
        }
        else if (value.includes('P')) {
            return value.replace('P', 'အပူး');
        } else if (value.includes('+')) {
            return value.replace('+', 'အပါ');
        }
        else if (value.includes('W')) {
            return value.replace('W', 'ပါ၀ါ');
        }
        else if (value.includes('K')) {
            return value.replace('K', 'နက္ခတ်');
        }
        else if (value.includes('B')) {
            return value.replace('B', 'ဘရိတ်');
        }
        else if (value.includes('*')) {
            if (value.toString().indexOf('*') == 0) {
                return value[1]+'နောက်';
            }

            if (value.toString().indexOf('*') == 1) {
                return value[0]+'ထိပ်';

            }
        }
        else {
            return value;
        }
    }
}