<template>
    <div class="two-d">
        <div class="position-absolute text-white ending-time d-flex align-items-center px-4">
            <span class="ms-auto fw-400 fs-14">ထီပိတ်ချိန် : 4:30 PM</span>
        </div>
        <div class="banner d-flex flex-column align-items-center justify-content-center">
            <div class="winning-number">
                <span class="fw-600 fs-36 blinker">{{ random_number }}</span>
            </div>
            <div class="d-flex align-items-center mt-3">
                <font-awesome-icon icon="fa-solid fa-circle-check" size="sm" class="text-white me-2" />
                <span class="fw-400 fs-13 text-white">Updated : {{ updated_at }}</span>
            </div>
        </div>
        <div class="content">
            <div class="p-3 border-bottom">
                <h1 class="fw-600 fs-18 mb-0">2D</h1>
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>
                </div>
                <div class="actions d-flex justify-content-around my-4">
                    <router-link class="text-decoration-none text-center"
                        :to="{ name: 'betting-list', params: { type: '2d' } }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">ထိုးမှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: 'winners' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-award"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">အနိုင်ရသူများ</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: '2d-closing-days' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-calendar-xmark" />
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">ထီပိတ်ရက်များ</p>
                    </router-link>
                </div>
                <PrimaryButton class="fw-700 fs-14" @click="showBetModal = true">ထိုးမည်</PrimaryButton>
            </div>

            <div class="p-3" v-for="(lottery, index) in lottries" :key="index">
                <h1 class="fw-600 fs-14">{{ index }}</h1>
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in lottery" :key="index" class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon v-if="section.day" icon="fa-solid fa-sun" class="text-custom me-2" />
                                <font-awesome-icon v-else icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">{{ $helper.formattedTime(section.opening_time) }}</span>
                            </div>
                            <div v-if="section.has_set_value">
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{ section.ended ?
                                    $helper.formattedNumber(section.set) : '...................'
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    section.ended ? $helper.formattedNumber(section.value) : '...................'
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">{{ section.ended ? section.winning_number : '??' }}</h1>
                    </li>
                </ul>
            </div>

            <!-- <div class="p-3">
                <h1 class="fw-600 fs-14">New York 2D</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">10:30 AM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">12:01 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">22</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                                <span class="fw-400 fs-16">2:30 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">16</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <div>
                                <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                                <span class="fw-400 fs-16">6:00 PM</span>
                            </div>
                            <div>
                                <span class="fw-400 fs-12 me-3">Set <span class="text-custom">{{
                                    $helper.formattedNumber(1599.82)
                                }}</span></span>
                                <span class="fw-400 fs-12">Value <span class="text-custom">{{
                                    $helper.formattedNumber(32122.25)
                                }}</span></span>
                            </div>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">27</h1>
                    </li>
                </ul>
            </div>

            <div class="p-3">
                <h1 class="fw-600 fs-14">Internet</h1>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-sun" class="text-custom me-2" />
                            <span class="fw-400 fs-16">10:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">06</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">5:00 PM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">38</h1>
                    </li>
                    <li class="list-group-item d-flex align-items-center">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-moon" class="text-custom me-2" />
                            <span class="fw-400 fs-16">2:30 AM</span>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">75</h1>
                    </li>
                </ul>
            </div> -->

        </div>
        <ModalBox :show="showBetModal" @close="showBetModal = false">
            <template v-slot:header>ထိုးမည့် Section ရွေးပါ</template>
            <template v-slot:body>
                <div class="pb-3" :class="{ 'py-3': x != 0, 'border-bottom': (x + 1) != lottries.length }"
                    v-for="(lottery, x) in lottries" :key="x">
                    <h1 class="fw-600 fs-14">{{ x }}</h1>
                    <div class="d-flex flex-wrap">
                        <div v-for="(value, y) in lottery" :key="y" :class="{ 'ps-2': (y % 2 != 0), 'pe-2': (y % 2 == 0) }"
                            class="col-6 mb-2">

                            <div d v-if="value.ending" class="text-center section rounded py-2 fw-600 fs-14 ended">
                                {{ $helper.formattedTime(value.opening_time) + ' (' + value.multiply + 'z)' }}
                            </div>

                            <div v-else class="text-center section rounded py-2 fw-600 fs-14"
                                :class="{ 'active': selectedSection == value.id }" @click="chooseSection(value.id)">
                                {{ $helper.formattedTime(value.opening_time) + ' (' + value.multiply + 'z)' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div class="col-6 pe-1">
                        <SecondaryButton class="fw-700 fs-14" @click="showBetModal = false">နောက်မှ</SecondaryButton>
                    </div>
                    <div class="col-6 ps-1">
                        <PrimaryButton class="fw-700 fs-14" @click="next()" :disabled="selectedSection == null">
                            ဆက်သွားမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
import moment from 'moment';
export default {
    components: {
        ModalBox,
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            lottries: [],
            random_number: String(Math.floor(Math.random() * 10)) + String(Math.floor(Math.random() * 10)),
            updated_at: moment().format('MMMM D YYYY h:m A')
        }
    },
    mounted() {

        userService.get2DScheduleList().then(({ data }) => {
            if (data.success) {
                this.lottries = data.data
            }
        })
        this.changeRamdomNumber()

    },
    methods: {
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '2d-section',
                params: {
                    section: this.selectedSection
                }
            })
        },
        changeRamdomNumber() {
            let that = this
            setInterval(function () {
                that.random_number = String(Math.floor(Math.random() * 10)) + String(Math.floor(Math.random() * 10))
                that.updated_at = moment().format('MMMM D YYYY h:m A')
            }, 1000 * 5)
        }
    }
}
</script>
<style>
.two-d .banner {
    margin-top: -1px;
    height: 21vh;
    background-color: #20c997;
}

.banner .winning-number {
    border-radius: 50%;
    background-color: #fff;
    color: #20c997;
    width: 19vw;
    height: 19vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
    margin-top: -1rem;
}

.two-d .action {
    width: 13vw !important;
    height: 12vw !important;
}

.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #F1F3FA;
}

.two-d .section.active {
    color: #fff;
    background-color: #20c997 !important;
}

.ended {
    color: #9e9e9e !important;
}

.blinker {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {

    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
</style>