<template>
    <div class="p-4">

        <form @submit="goToWithDraw()" class="d-flex flex-column h-100">
            <template v-if="step == 1">
                <div class="options row p-0">
                    <div @click="selectPayment(key)" class="col-6" :class="{
                        'ps-0': (key + 1 % 2 != 0),
                        'pe-0': (key + 1 % 2 == 0),
                    }" v-for="(value, key) in payment_options" :key="key">
                        <div :class="{
                            'border-primary': selectedPayment == key,
                            'selected-payment': selectedPayment == key
                        }" class="d-flex align-items-center border rounded px-2 py-2">
                            <img :src="require('@/assets/payments/' + value.icon)" alt="">
                            <label class="form-check-label mx-2" for="inlineRadio2">{{ value.name }}</label>
                            <font-awesome-icon v-if="selectedPayment == key" icon="fa-regular fa-circle-dot"
                                class="text-primary ms-auto" size="lg" />
                            <font-awesome-icon v-else icon="fa-regular fa-circle" class="text-primary ms-auto" size="lg" />
                        </div>
                    </div>

                </div>
                <div class="mt-4">
                    <h1 class="fw-600 fs-18 mb-4">{{ payment_options[selectedPayment].name }} <span
                            class="fw-400 fs-14">နှင််ငွေထုတ်မည်</span> </h1>
                    <form-group :required="true" label="Receiver Account Name"
                        placeholder="Enter a receiver account name"></form-group>
                    <form-group :required="true" label="Receiver Account Number"
                        placeholder="Enter a receiver account number"></form-group>
                    <form-group :required="true" label="Confirm Receiver Account Number"
                        placeholder="Re-enter a receiver account number"></form-group>

                </div>


                <div class="mt-3 d-flex align-items-center">
                    <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                    <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
                </div>

                <PrimaryButton type="submit" class="login-btn mt-auto" @click="goToStep2()">ဆက်သွားမည်</PrimaryButton>
            </template>
            <template v-else-if="step == 2">
                <div class="d-flex align-items-center">
                    <img style="width:15vw" src="@/assets/payments/kpay.svg" class="me-3">
                    <div>
                        <span class="fw-400 fs-12">ငွေလက်ခံမည့် နံပါတ်</span>
                        <h1 class="fw-600 fs-14 mb-0">Aung Kyaw</h1>
                        <span class="fw-400 fs-12">*******5678</span>
                    </div>
                    <span class="fw-600 fs-14 text-primary ms-auto">Change</span>
                </div>

                <div class="mt-4">
                    <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
                    <div class="row">
                        <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                            class="col-4 mb-2 px-1">
                            <div :class="{
                                'bg-success text-white': selectedAmount == amount,
                                'text-primary bg-amount': selectedAmount != amount
                            }" class="amount rounded text-center">
                                {{ $helper.formattedNumber(amount) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center my-2">
                    <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
                    <span class="fw-400 fs-14 mx-2">or</span>
                    <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
                </div>

                <div class="mt-2">
                    <form-group v-model="selectedAmount" label="Amount" placeholder="Enter amount"></form-group>
                </div>

                <div class="mt-3 d-flex align-items-center">
                    <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                    <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
                </div>

                <div class="mt-auto d-flex">
                    <div class="w-50 me-1">
                        <OrdinaryButton @click="step = 1" type="submit" class="login-btn mt-auto fw-700 fs-14">နောက်သို့
                        </OrdinaryButton>

                    </div>
                    <div class="w-50 ms-1">
                        <PrimaryButton @click="step = 3" type="submit" class="login-btn mt-auto fw-700 fs-14">
                            တင်ပြမည်
                        </PrimaryButton>
                    </div>
                </div>
            </template>
        </form>
        <modal :show="showWarningModal">
            <template v-slot:header>ငွေထုတ်ရင်သတိပြုရမည့်အချက်များ</template>
            <template v-slot:body>
                <ul class="">
                    <li class="fw-400 fs-14">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                        duis
                        enim
                        velit mollit. Exercitation veniam consequat sunt nostrud amet.
                    </li>
                    <li class="fw-400 fs-14">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                        officia consequat
                        duis
                        enim
                        velit mollit.</li>
                </ul>

                <PrimaryButton @click="showWarningModal = false">OK</PrimaryButton>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from '@/components/ModalComponent.vue';
export default {
    components: {
        Modal
    },
    data() {
        return {
            step: 1,
            showWarningModal: true,
            selectedPayment: 0,
            selectedAmount: null,
            payment_options: [
                {
                    name: "KBZ Pay",
                    icon: "kpay.svg"
                },
                {
                    name: "Wave Pay",
                    icon: "wave.svg"
                }
            ],
            errors: [

            ],
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ]
        };
    },
    methods: {
        goToWithDraw() {
            this.$router.push({
                name: "withdraw"
            });
        },
        selectPayment(index) {
            this.selectedPayment = index;
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
        },
        goToStep2() {
            // if ()
        }
    },
}
</script>
<style>
.selected-payment {
    background-color: #20c9971A;
}

.bg-amount {
    background: #EBF0FD;
}

.amount-input {
    background: #EBF0FD;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
