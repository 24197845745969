<template>
    <div>
        <div class="px-2 py-1">
            <div class="row align-items-center">
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="Start Date" v-model="start_date" class="form-control" type="date">
                    </div>
                </div>
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="End Date" v-model="end_date" class="form-control" type="date">
                    </div>
                </div>


                <div class="col-10 p-1">
                    <div class="form-group">
                        <select class="form-control" v-model="type">
                            <option value="">All</option>
                            <option :value="type.id" v-for="(type, index) in types" :key="index">{{ type.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-2">
                    <FontAwesomeIcon @click="fetchData" :icon="['fas', 'fa-refresh']" size="lg" class="ms-3">
                    </FontAwesomeIcon>

                </div>

            </div>



        </div>
    </div>
    <div class="d-flex bg-success text-white p-2">
        <div class="col-3">Date</div>
        <div class="col-5 text-center">Type</div>
        <div class="col-4 text-center">Amount</div>
    </div>
    <div>
        <template v-for="(   list, index   ) in    data   " :key="index">
            <div class="d-flex text-success p-2 border-bottom">
                <div class="col-3">{{ list.date }}</div>
                <div class="col-5 text-center">{{ list.key }}</div>
                <div class="col-4 text-center">{{ list.value }}</div>
            </div>

        </template>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
    data() {
        return {
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            data: null,

        };
    },
    mounted() {
        userService.getTypes().then(({ data }) => {
            this.types = data.data
        })

    },
    methods: {
        fetchData() {
            this.$store.dispatch('loading')
            userService.getBalanceHistory(this.start_date, this.end_date, this.section_id, this.price, this.name).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.data = data
            });

        }
    },
    components: { FontAwesomeIcon }
}
</script>
<style>
.winners-nav {
    background-color: #20c997;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>