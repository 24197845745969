<template>
    <div class="bg-thin d-flex p-3">
        <span class="fw-400 fs-13">Unitရောင်းမည့်ပမာဏ:</span>
        <span class="fw-600 fs-13 ms-2">{{ $helper.formattedNumber($store.state.cash.selectedWithdrawAmount) }}
            Unit</span>
        <router-link class="fw-600 fs-13 text-decoration-none ms-auto" :to="{ name: 'withdraw' }">ပြုပြင်မည်</router-link>
    </div>
</template>
<script>
export default {
    mounted() {
        console.log()
    }
}
</script>