<template>
    <div>
        <SelectedTopUpAmount></SelectedTopUpAmount>
        <div class="p-4">
            <div class="bg-thin p-3 rounded-4 mb-3" v-for="(agent, index) in agents" :key="index">
                <div class="d-flex align-items-center">
                    <div class="col-1">
                        {{ index + 1 }}.
                    </div>
                    <div class="col-7">
                        <div class="d-flex align-items-center">
                            <img width="62" height="62" class="rounded-circle" :src="agent.image" alt="">
                            <div class="ms-2">
                                <h1 class="fw-600 fs-13 mb-0">{{ agent.name }}</h1>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-star" class="text-warning"></font-awesome-icon>
                                    <span class="fw-400 fs-13">80%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-end">
                        <router-link :to="{ name: 'top-up.agent', params: { agent: agent.id } }"
                            class="btn btn-primary fw-700 fs-13">ငွေဖြည့်မည်</router-link>
                    </div>
                </div>
                <div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/dollar-circle.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">ရောင်းမည့်ပမာဏ</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ $helper.formattedNumber(agent.minimum_amount) }} Unit to {{
                                    $helper.formattedNumber(agent.maximum_amount) }} Unit
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/wallet-money.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">Agent လက်ကျန်ငွေ</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ $helper.formattedNumber(agent.balance) }} Unit
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/empty-wallet.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">ဖြည့်သွင်းနိုင်သည့် Pay အမျိုးအစား</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ agent.payment_methods.map(p => p.name).join(',') }}
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/clock.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">Unit ဖြည့်ကြာချိန်</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ agent.duration }} mins
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectedTopUpAmount from '@/components/SelectedTopUpAmountComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        SelectedTopUpAmount
    },
    data() {
        return {
            agents: []
        }
    },
    mounted() {
        this.fetchAgentList()
    },
    methods: {
        fetchAgentList() {
            this.$store.dispatch('loading')
            userService.getDepositAgentList(this.$store.state.cash.selectedTopUpAmount).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                console.log(data.data)
                this.agents = data.data
            })
        }
    }
}
</script>