<template>
                    <div class="position-relative mx-auto" style="max-width: 400px;">
                        <nav class="nav d-flex align-items-center justify-content-center" v-if="navbar">
                          <font-awesome-icon v-if="backButton" @click="$router.go(-1)"
                            class="go-back-button text-white ms-4 position-absolute" icon="fa-solid fa-chevron-left" />
                          <p class="fw-600 fs-14 text-white mb-0">{{ $route.meta.title }}</p>
                        </nav>
                        <router-view @toast="toast" :style="{
                          height: viewHeight, marginBottom: viewMargin
                        }" />
                        <!-- <footer-navbar v-if="footerNavbar"></footer-navbar> -->
                        <alert :message="alertMessage"/>
                        <toast :message="toastMessage"/>
                        <loading :loading="loading"></loading>
                </div>
</template>

<script>

                                      import Alert from './components/AlertComponent.vue';
                                      import Toast from './components/ToastComponent.vue';

                                      import Loading from './components/LoadingComponent.vue';
                                      export default {
                                        name: 'App',
                                        components: {
                                          Alert,
                                          Loading,
                                          Toast
                                        },
                                        data() {
                                          return {
                                            navbar: true,
                                            footerNavbar: true,
                                            backButton: true,
                                            showAlert: false,
                                            alertMessage: null,
                                            toastMessage: null
                                          }
                                        },
                                        mounted() {
                                          this.$store.dispatch('getHomeBanner')
                                          this.$store.dispatch('getServiceBanner')
                                          this.$store.dispatch('cash/getPaymentMethods')

                                        },
                                        computed: {
                                          viewHeight() {
                                            let view = 100;
                                            if (this.navbar) {
                                              view -= 6;
                                            }
                                            return view + 'vh';
                                          },
                                          viewMargin() {
                                            return this.footerNavbar ? "50px" : "0";
                                          },
                                          loading() {
                                            return this.$store.state.index.loading
                                          }
                                        },
                                        methods: {
                                          alert(message) {
                                            this.alertMessage = message
                                            setTimeout(() => {
                                              this.alertMessage = null
                                              this.$store.state.index.errorMessage = null
                                            }, 2000)
                                          }
                                        },
                  watch: {
                    '$route': {
                      immediate: true,
                      handler() {
                        this.navbar = this.$route.meta.navbar
                        this.footerNavbar = this.$route.meta.footerNavbar
                        this.backButton = this.$route.meta.backButton
                      }
                    },
                    '$store.state.index.errorMessage': {
                      immediate: true,
                      handler() {
                        let message = this.$store.state.index.errorMessage
                        if (message) {
                          this.alert(message)
                        }
                      }
                    },
                    '$store.state.index.toastMessage': {
                      immediate: true,
                      handler() {
                        let message = this.$store.state.index.toastMessage
                        if (message) {
                          this.toastMessage = message
                          setTimeout(() => {
                            this.toastMessage = null
                            this.$store.state.index.toastMessage = null
                          }, 2000)
                        }
      }
    }
  }
}
</script>

<style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar&display=swap');
            #app {
              font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.horizontal-line {
  border: 1px solid #F1F3FA;
}

.go-back-button {
  left: 0;
}

.text-primary,.text-custom{
  color: #20c997 !important;
}

.bg-success,.bg-customer{
  background-color: #20c997 !important;
}

.bg-thin{
  background-color: #EBF0FD !important;
}

.nav {
  background-color: #20c997;
  height: 6vh;
}

.font-poppins {
  font-family: 'Poppins', sans-serif !important;
}

.font-noto {
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

.title {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Poppins', sans-serif !important;
}

.subtitle {
  font-weight: 400;
  font-size: 13px;
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

label {
  font-weight: 600;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

.sub-label {
  font-weight: 400;
  font-size: 14px;
  font-family: 'Noto Sans Myanmar', sans-serif !important;
}

.w-20{
  width: 20% !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-12 {
  font-size: .75rem !important;
}

.fs-13 {
  font-size: .8125rem !important;
}

.fs-14 {
  font-size: .875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-17{
  font-size: 1.0625rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-36 {
  font-size: 2.25rem !important;
}



.notice {
   padding: 1rem;
   border-radius:16px;
   display:flex;
   align-items:center;
}

.notice.warning{
    background-color: #E5B22F1A;
    border: 1.7px solid #E5B22F;
}

.notice.success {
    background-color: #06C2261A;
    border: 1.7px solid #06C226;
}


.notice.fail {
    background-color: #E5B22F1A;
    border: 1.7px solid #E5B22F;
}



</style>
