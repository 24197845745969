<template>
    <div class="p-4">
        <form @submit="handleRegister()" class="d-flex flex-column h-100">

            <template v-if="step == 1">
                <h1 class="title mb-3">Register</h1>
                <p class="subtitle mb-4">အကောင့်ပြုလုပ်ရန် သင့်ဖုန်းအချက်အလက်ရိုက်ထည့်ပါ</p>

                <div>
                    <div class="d-flex flex-wrap mb-4 align-items-center">
                        <div class="col-5">
                            <img v-if="previewImage" :src="previewImage" class="profile-image rounded-circle" alt="">
                            <div v-else class="profile-image rounded-circle">
                                <font-awesome-icon icon="fa-solid fa-user" size="2xl" class="text-primary" />
                            </div>
                            <input ref="chooseImage" type="file" class="d-none" @change="imageChoosed">
                        </div>
                        <div class="col-6">
                            <SecondaryButton type="button" class="mb-2 fw-600 fs-14">Take Photo
                            </SecondaryButton>
                            <SecondaryButton type="button" @click="chooseImage()" class="fw-600 fs-14">Upload Image
                            </SecondaryButton>
                        </div>
                        <div class="col-12">
                            <small class="text-danger fw-400 fs-12" v-for="(message, index) in errors.image" :key="index">{{
                                message }}</small>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="col-8">
                            <FormGroup v-model="phone" :success="success.phone" :errors="errors.phone" label="Phone Number"
                                placeholder="09123456789" type="number">
                            </FormGroup>
                        </div>
                        <div class="col-4 text-center">
                            <a @click="getOTP()" class="text-decoration-none text-primary get-otp">{{ OTPLabel }}</a>
                        </div>
                    </div>
                    <FormGroup :required="true" v-model="otp" :errors="errors.otp" label="OTP Code"
                        placeholder="Enter OTP Code" type="number">
                    </FormGroup>
                    <FormGroup :required="true" v-model="name" :errors="errors.name" label="Username"
                        placeholder="Enter your name" type="text">
                    </FormGroup>
                </div>
                <PrimaryButton @click="step = 2" class="login-btn mt-auto">Register</PrimaryButton>
            </template>
            <template v-else>
                <h1 class="title mb-3">Create New Pin</h1>

                <div class="notes mb-2">
                    <div class="d-flex align-items-center justify-content-around mb-3">
                        <div class="col-1">
                            <font-awesome-icon class="text-warning" icon="fa-solid fa-triangle-exclamation" size="lg" />
                        </div>
                        <div class="col-10">
                            <span class="subtitle">PINသည် Login နှင့် ငွေထုတ်ပြုလုပ်ရာတွင်
                                အသုံးပြုရန်လိုအပ်သည်</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-around mb-3">
                        <div class="col-1">
                            <font-awesome-icon class="text-warning" icon="fa-solid fa-triangle-exclamation" size="lg" />
                        </div>
                        <div class="col-10">
                            <span class="subtitle">သင့် PIN ကိုမည်သူ့ကိုမှ မမျှ၀ေပါနှင့်</span>
                        </div>
                    </div>
                </div>

                <div>
                    <FormGroup v-model="password" min="8" :errors="errors.password" label="New Pin"
                        placeholder="Enter new Pin" type="password">
                    </FormGroup>
                    <FormGroup v-model="password" label="Confirm New Pin" placeholder="Enter new Pin" type="password">
                    </FormGroup>
                </div>

                <OrdinaryButton class="mt-auto" @click="step = 1">Back</OrdinaryButton>
                <PrimaryButton type="submit" class="login-btn mt-2">Confirm</PrimaryButton>
            </template>

        </form>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            step: 1,
            OTPSeconds: 60,
            OTPLabel: "Get OTP",
            previewImage: null,
            phone: "",
            name: "",
            password: "",
            image: "",
            otp: "",
            errors: {},
            success: {},
        };
    },
    methods: {
        next() {
            this.$router.push({
                name: 'new-pin',
                params: {
                    phone: this.phone,
                    name: this.name
                }
            })
        },
        getOTP() {
            userService.getOTP({
                phone: this.phone
            }).then(({ data }) => {
                if (data.success) {
                    this.success.phone = data.message
                    console.log(this.success.phone)
                    var otpInterval = setInterval(() => {
                        this.OTPSeconds--;

                        if (this.OTPSeconds <= 0) {
                            clearInterval(otpInterval)
                            this.OTPSeconds = 60;
                            this.OTPLabel = "Get OTP"
                        }

                        this.OTPLabel = this.OTPSeconds + "s"
                    }, 1000)
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors
                }

                if (!response.data.success) {
                    console.log(response)
                    this.errors.phone = [response.data.message]
                }
            })
        },
        chooseImage() {
            this.$refs.chooseImage.click()
        },
        imageChoosed(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
            this.image = e.target.files[0]

        },
        handleRegister() {
            event.preventDefault();
            let data = new FormData();
            data.append('image', this.image);
            data.append('phone', this.phone);
            data.append('name', this.name);
            data.append('password', this.password);
            data.append('otp', this.otp)
            this.$store.dispatch('loading')
            this.$store.dispatch('auth/register', data).then((data) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$router.push({ name: 'get-started' })
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    this.errors = response.data.errors
                    if (this.errors.phone && this.errors.phone.length > 0 || this.errors.otp && this.errors.otp.length > 0) {
                        this.step = 1;
                    }
                }
            })
        },
    },
}
</script>

<style>
.profile-image {
    width: 100px;
    height: 100px;
    background-color: #EBF0FD;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-otp {
    font-weight: 800;
    position: relative;
    top: 7px;
}
</style>
