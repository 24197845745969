<template>
    <div>
        <div class="px-2 py-1">
            <div class="row align-items-center">
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="Start Date" v-model="start_date" class="form-control" type="date">
                    </div>
                </div>
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="End Date" v-model="end_date" class="form-control" type="date">
                    </div>
                </div>
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="အမည်" v-model="name" class="form-control" type="text">
                    </div>
                </div>
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="Price" v-model="price" class="form-control" type="text">
                    </div>
                </div>

                <div class="col-4 p-1">
                    <div class="form-group">
                        <select class="form-control" v-model="type">
                            <option value="">All</option>
                            <option :value="type.id" v-for="(type, index) in types" :key="index">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" disabled v-model="getTotalAmount" class="form-control text-center">
                    </div>
                </div>

                <div class="col-2">
                    <FontAwesomeIcon @click="fetchData" :icon="['fas', 'fa-refresh']" size="lg" class="ms-3">
                    </FontAwesomeIcon>

                </div>

            </div>



        </div>
    </div>
    <div class="d-flex bg-success text-white p-2">
        <div class="col-2">Date</div>
        <div class="col-2">Type</div>
        <div class="col-2">Name</div>
        <div class="col-2">Code</div>
        <div class="col-2">Price</div>
        <div class="col-2">Amount</div>
    </div>
    <div>
        <template v-for="(   voucher, index   ) in    data   " :key="index">
            <template v-for="(   group, index   ) in    voucher.groups   " :key="index">
                <div class="d-flex">
                    <div class="col-2">
                        <small>{{ voucher.date }}</small>
                    </div>
                    <div class="col-2">
                        <small>{{ voucher.type }}</small>
                    </div>
                    <div class="col-2">
                        <small>{{ group.name }}</small>
                    </div>
                    <div class="col-2">
                        <small>{{ group.number ? $2d.getMMName(group.number) : '' }}</small>
                    </div>
                    <div class="col-2">
                        <small>{{ group.amount }}</small>
                    </div>
                    <div class="col-2">
                        <small>{{ group.total_amount }}</small>
                    </div>
                </div>
            </template>
            <div class="bg-success d-flex p-1 align-items-center justify-content-between text-white">
                <FontAwesomeIcon class="text-white" :icon="['fas', 'fa-print']"></FontAwesomeIcon>
                <div>{{ voucher.voucher_number }}</div>
                <div>
                    {{ voucher.amount }}
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import userService from '@/services/user.service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
    data() {
        return {
            type: '',
            data: [],
            name: '',
            price: '',
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            section_id: null,
            types: [],
            total_amount: 0,
        };
    },
    mounted() {
        if (this.$route.params.type) {
            this.type = this.$route.params.type;
        }
        this.section_id = this.$route.params.section;
        userService.getTypes().then(({ data }) => {
            this.types = data.data
        })
    },
    computed: {
        getTotalAmount() {
            return 'Total : ' + this.data.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
        }
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loading')
            userService.getUser2DBettingHistory(this.start_date, this.end_date, this.section_id, this.price, this.name).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.data = data.data;
                console.log(data)
            });

            userService.getTransactionHistory().then(({ data }) => console.log(data))
        }
    },
    components: { FontAwesomeIcon }
}
</script>
<style>
.winners-nav {
    background-color: #20c997;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>