<template>
    <div>
        <div v-if="section" class="bg-success text-white text-center py-2">
            {{ section.type }} {{ section.opening_time }}
        </div>
        <div>
            <HotNumbersComponent></HotNumbersComponent>
        </div>
    </div>
</template>
<script>
import HotNumbersComponent from '@/components/2DHotNumbersComponent';
import userService from '@/services/user.service'
export default {
    components: {
        HotNumbersComponent
    },
    data() {
        return {
            section: null
        }
    },
    mounted() {
        userService.get2DScheduleDetail(this.$route.params.section).then(({ data }) => {
            this.section = data.data
        })
    },
}
</script>