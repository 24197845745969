<template>
    <div class="loading-box-backdrop" v-if="loading > 0">
        <div class="loading px-3 pt-2">
            <span class="text-white mx-3">ခဏစောင့်ပါ</span>
            <div class="d-flex justify-content-center mt-auto">
                <PulseLoader color="white" :loading="true" size="8px"></PulseLoader>
            </div>
        </div>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    components: {
        PulseLoader
    },
    props: {
        loading: {
            type: Number,
            default: 0
        }
    }
}
</script>
<style scoped>
.loading-box-backdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.loading {
    /* width: 25vw; */
    background-color: #20c997;
    border-radius: .8rem;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    display: flex;
    flex-direction: column;
    /* aspect-ratio: 1/1; */
}
</style>