<template>
    <div class="home">
        <div class="bg-success px-3 pt-2" style="height: 90px;" v-if="currentUser">
            <div style="height: 50px;" class="d-flex align-items-center">
                <img src="@/assets/logo.png" width="50" height="50" alt="">
                <span class="text-white ms-2 fw-bold h4 m-0">{{ currentUser.name }}</span>
                <span class="ms-auto text-white" @click="logout()">
                    <font-awesome-icon :icon="['fas', 'fa-arrow-right']"></font-awesome-icon>
                    Logout
                </span>
            </div>

        </div>




        <div class="bg-warning pt-3"
            style="margin-top: -20px;border-top-right-radius: 15px;border-top-left-radius: 15px;min-height: 90vh;">
            <div v-if="logined" class="welcome-box mx-3">
                <div class="d-flex align-items-center user-info bg-success p-3">
                    <div>
                        <img src="@/assets/user.png" class="rounded-circle" width="50"
                            style="aspect-ratio: 1/1;border-width: .1rem;border-width: .3rem;" alt="">
                    </div>
                    <div class="text-white text-start ms-2">
                        <h1 class="user-name mb-1">{{ currentUser.name }}</h1>
                    </div>
                    <router-link :to="{ name: 'profile.view' }" class=" ms-auto">
                        <div class="user-info-edit rounded-circle">
                            <font-awesome-icon class="text-white" icon="fa-solid fa-pencil"></font-awesome-icon>
                        </div>
                    </router-link>
                </div>

                <div class="d-flex">
                    <div class="user-balance-info text-start px-3 py-2 d-flex align-items-center">
                        <div>
                            <p class="user-phone mb-0">Available Balance</p>
                            <h1 class="user-balance mb-0">{{ currentUser.balance ?
                                $helper.formattedNumber(currentUser.balance)
                                : 0 }} Unit</h1>
                        </div>

                    </div>
                    <div class="ms-auto p-2">
                        <div>
                            ကော် : {{ currentUser.commision_percentage }}
                        </div>
                        <div>
                            ဆ : {{ currentUser.multiply }}
                        </div>
                    </div>

                    <!-- <div class="user-actions d-flex justify-content-around">
                        <router-link class="text-decoration-none" :to="{ name: 'top-up' }">
                            <SecondaryButton :disabled="false" class="px-3 mb-1 action">
                                <font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon>
                            </SecondaryButton>
                            <p class="mb-0 fw-400 fs-13">ငွေဖြည့်</p>
                        </router-link>
                        <router-link class="text-decoration-none" :to="{ name: 'withdraw' }">
                            <SecondaryButton class="px-3 mb-1 action">
                                <font-awesome-icon icon="fa-solid fa-circle-up"></font-awesome-icon>
                            </SecondaryButton>
                            <p class="mb-0 fw-400 fs-13">ငွေထုတ်</p>
                        </router-link>
                        <router-link class="text-decoration-none" :to="{ name: 'transaction-history' }">
                            <SecondaryButton class="px-3 mb-1 action">
                                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
                            </SecondaryButton>
                            <p class="mb-0 fw-400 fs-13">မှတ်တမ်း</p>
                        </router-link>
                    </div> -->

                </div>

            </div>

            <div class="my-2">
                <marquee behavior="" direction="">
                    <small>
                        <font-awesome-icon icon="fa-solid fa-bullhorn" class=""></font-awesome-icon>
                        {{currentUser.home_banner_text}}
                    </small>
                </marquee>
            </div>

            <div class="p-3 pt-0">
                <div class="row">
                    <template v-for="(section, index) in sections" :key="index">
                        <div class="col-6 mb-3">
                            <router-link class="text-decoration-none text-success"
                                :to="{ name: '2d-section', params: { section: section.id } }">
                                <div style="min-height: 120px;"
                                    class="d-flex flex-column justify-content-center align-items-center bg-white rounded text-center p-2">
                                    <img src="@/assets/2d.png" width="40" alt="" class="mb-2">
                                    <div>
                                        <b>
                                            <small>({{ section.type }})</small> {{ section.opening_time }}
                                        </b>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-6 mb-3">
                            <router-link class="text-decoration-none text-success"
                                :to="{ name: 'hot-block-nums', params: { section: section.id } }">
                                <div style="min-height: 120px;"
                                    class="d-flex flex-column justify-content-center align-items-center bg-white rounded text-center p-2">
                                    <img src="@/assets/2d.png" width="40" alt="" class="mb-2">
                                    <div>
                                        <b>
                                            <small>ဟော့နှင့်မရဂဏန်းများ</small>
                                        </b>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>

                    <div class="col-6 mb-3">
                        <router-link class="text-decoration-none text-success" :to="{ name: 'remaining-history' }">
                            <div style="min-height: 120px;" class="bg-white rounded text-center p-4">
                                <img src="@/assets/money.png" width="40" alt="">
                                <div>
                                    <b>
                                        လက်ကျန်စာရင်း
                                    </b>
                                </div>
                            </div>
                        </router-link>
                    </div>


                    <div class="col-6 mb-3">
                        <router-link class="text-decoration-none text-success" :to="{ name: 'betting-list' }">
                            <div style="min-height: 120px;" class="bg-white rounded text-center p-4">
                                <img src="@/assets/checklist.png" width="40" alt="">
                                <div>
                                    <b>
                                        စာရင်းများ
                                    </b>
                                </div>
                            </div>
                        </router-link>
                    </div>


                    <div class="col-6 mb-3">
                        <router-link class="text-decoration-none text-success" :to="{ name: 'profit-history' }">
                            <div style="min-height: 120px;" class="bg-white rounded text-center p-4">
                                <img src="@/assets/profit.png" width="40" alt="">
                                <div>
                                    <b>
                                        နိုင်/ရှုံးစာရင်း
                                    </b>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>




                <!-- <router-link  class="w-100">
                    <OutlineButton>
                        ထိုးမှတ်တမ်း
                        <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon>
                    </OutlineButton>
                </router-link> -->
            </div>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
// import { Slide, Pagination, Navigation } from 'vue3-carousel'
import userService from '@/services/user.service'
export default {
    components: {
        // Carousel,
        // Slide,
        // Pagination,
        // Navigation,
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            sections: []
        }
    },
    computed: {
        homeBannerText() {
            return this.$store.state.index.homeBannerText
        },
        bannerImages() {
            return this.$store.state.index.homeBannerImages;
        }
    },
    mounted() {

        if (this.logined) {
            this.$store.dispatch('auth/updateUserInfo')
        }
        this.fetchSections()


    }, methods: {
        fetchSections() {
            userService.get2DScheduleList().then(({ data }) => {
                console.log(data)
                if (data.success) {
                    this.sections = data.data
                }
            })
        },
        logout() {
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
            // window.location.reload()
        }
    }
}
</script>
<style>
.home-banner {
    height: 25vh;
    width: 100%;
    background: url('../assets/home-banner.png');
    background-position: center;
    background-size: 100%;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.banner-title {
    font-weight: 600;
    font-size: 16px;
}

.welcome-box {
    /* margin-top: -30px; */
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #6D7FA333;
    border-radius: 14px;
    text-align: center;
}

.welcome-box .user-info {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.ad-box {
    border: 2px solid #ECEEF5;
    border-radius: 14px;
}

.ad-box img {
    border-radius: 14px 14px 0px 0px;
    /* height: 200px; */
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.ad-box .ad-description {
    font-size: 13px;
    font-weight: 400;
}

.ad-box .ad-description-box {
    background-color: #447BE833;
    padding-top: 5px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.user-balance-deposit-label {
    font-weight: 400;
    font-size: 12px;
}

.user-name {
    font-weight: 600;
    font-size: 14px;
}

.user-phone {
    font-weight: 400;
    font-size: 12px;
}

.user-balance {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.user-info-edit {
    background: #00000033;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-box .welcome-title {
    font-weight: 600;
    font-size: 18px;
}

.games-box .title {
    font-weight: 700;
    font-size: 16px;
}

.user-actions .action {
    width: 13vw !important;
    height: 12vw !important;
}

.home {
    overflow: scroll;
    /* padding-bottom: 10vh !important; */
}

.carousel {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
</style>

