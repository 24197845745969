<template>
    <div>
        <div class="px-2 py-1">
            <div class="row align-items-center">
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="Start Date" v-model="start_date" class="form-control" type="date">
                    </div>
                </div>
                <div class="col-6 p-1">
                    <div class="form-group">
                        <input placeholder="End Date" v-model="end_date" class="form-control" type="date">
                    </div>
                </div>


                <div class="col-10 p-1">
                    <div class="form-group">
                        <select class="form-control" v-model="type">
                            <option value="">All</option>
                            <option :value="type.id" v-for="(type, index) in types" :key="index">{{ type.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-2">
                    <FontAwesomeIcon @click="fetchData" :icon="['fas', 'fa-refresh']" size="lg" class="ms-3">
                    </FontAwesomeIcon>

                </div>

            </div>



        </div>
    </div>
    <template v-if="data">
        <div class=" bg-warning p-3 ">

            <div class="d-flex align-items-center justify-content-end">
                <h6 class="fw-bold mb-0"
                    :class="{ 'text-danger': data.total_revenue < 0, 'text-success': data.total_revenue >= 0 }">Total <span
                        class="mx-2">:</span> {{
                            data.total_revenue }}</h6>
            </div>
            <div class="d-flex align-items-center justify-content-end"
                :class="{ 'text-danger': data.total_betting_amount < 0, 'text-success': data.total_betting_amount >= 0 }">
                <h6 class="fw-bold mb-0">ရောင်း <span class="mx-2">:</span> {{ data.total_betting_amount }}</h6>
            </div>
        </div>

        <div>
            <template v-for="(   list, index   ) in    data.lists   " :key="index">
                <div class="d-flex text-white p-2 border-bottom border-top align-items-center">
                    <div class="bg-success rounded-circle d-flex align-items-center justify-content-center"
                        style="width: 40px;height: 40px;font-size: 18px;font-weight: bold;">
                        {{ list.winning_number }}
                    </div>
                    <div class="text-success ms-2">
                        {{ list.title }} <br>
                        {{ list.opening_date_time }}
                    </div>
                    <div class="text-success ms-2">
                        ထိ : {{ list.winning_amount }}
                    </div>
                    <div class="ms-auto fw-bold"
                        :class="{ 'text-success ': list.revenue_amount >= 0, 'text-danger': list.revenue_amount < 0 }">
                        {{ list.revenue_amount }}
                    </div>
                </div>
                <div class="d-flex justify-content-between py-3 fw-bold text-success px-1">
                    <div>
                        ရောင်း : {{ list.betting_amount }}
                    </div>

                    <div>
                        ကော် : {{ list.commision_amount }}
                    </div>
                </div>

            </template>
        </div>
    </template>
</template>
<script>
import userService from '@/services/user.service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
    data() {
        return {
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            data: null,
            types: []
        };
    },
    mounted() {
        userService.getTypes().then(({ data }) => {
            this.types = data.data
        })
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loading')
            userService.getProfitHistory(this.start_date, this.end_date, this.section_id, this.price, this.name).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.data = data
                console.log(data)
            });

        }
    },
    components: { FontAwesomeIcon }
}
</script>
<style>
.winners-nav {
    background-color: #20c997;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
    color: #fff;
    text-decoration: none;
}

.winner-nav-item.active {
    color: white !important;
    background-color: #2B5DDC !important;
}
</style>