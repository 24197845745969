<template>
    <div class="two-d">
        <div class="position-absolute text-white ending-time d-flex align-items-center px-4">
            <span class="ms-auto fw-400 fs-14">ထီပိတ်ချိန် : 4:30 PM</span>
        </div>
        <div class="banner d-flex flex-column align-items-center justify-content-center">
            <div class="winning-number">
                <span class="fw-600 fs-36">46</span>
            </div>
            <div class="d-flex align-items-center mt-3">
                <font-awesome-icon icon="fa-solid fa-circle-check" size="sm" class="text-white me-2" />
                <span class="fw-400 fs-13 text-white">Updated : Oct 12 2022 14:58 AM</span>
            </div>
        </div>
        <div class="content">
            <div class="p-3 border-bottom">
                <h1 class="fw-600 fs-18 mb-0">3D</h1>
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" />
                    <span class="fw-400 fs-14 mx-2">လက်ကျန်ငွေ</span>
                    <span class="fw-600 fs-14">{{ $helper.formattedNumber(currentUser.balance) }} Ks</span>
                </div>
                <div class="actions d-flex justify-content-around my-4">
                    <router-link class="text-decoration-none text-center"
                        :to="{ name: 'betting-list', params: { type: '3d' } }">
                        <SecondaryButton :disabled="false" class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">ထိုးမှတ်တမ်း</p>
                    </router-link>
                    <router-link class="text-decoration-none text-center" :to="{ name: 'winners' }">
                        <SecondaryButton class="px-3 mb-1 action rounded-3">
                            <font-awesome-icon icon="fa-solid fa-award"></font-awesome-icon>
                        </SecondaryButton>
                        <p class="mb-0 fw-400 fs-13 text-dark">အနိုင်ရသူများ</p>
                    </router-link>
                </div>
                <PrimaryButton :disabled="!selectedSection" class="fw-700 fs-14" @click="next()">ထိုးမည်
                </PrimaryButton>
            </div>

            <div class="p-3">
                <ul class="list-group list-group-flush">
                    <li v-for="(section, index ) in history" :key="index" class="list-group-item d-flex align-items-center">
                        <div>
                            <span class="fw-400 fs-12">Date</span>
                            <p class="fw-600 fs-16 mb-0">{{ section.date }}</p>
                        </div>
                        <h1 class="ms-auto fw-600 fs-24">{{ section.winning_number }}</h1>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import userService from '@/services/user.service';
export default {
    components: {
        PrimaryButton
    },
    data() {
        return {
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            showBetModal: false,
            selectedSection: null,
            lottery: false,
            history: []
        }
    },
    mounted() {
        console.log(this.currentUser)
        userService.get3DScheduleHistory().then(({ data }) => {
            this.history = data.data
        }).catch(response => {
            console.log(response)
        })

        userService.get3DScheduleList().then(({ data }) => {
            console.log(data)
            this.selectedSection = data.data
        })


    },
    methods: {
        chooseSection(section) {
            this.selectedSection = section
        },
        next() {
            this.$router.push({
                name: '3d-section',
                params: {
                    section: this.selectedSection
                }
            })
        }
    }
}
</script>
<style>
.two-d .banner {
    margin-top: -1px;
    height: 21vh;
    background-color: #20c997;
}

.banner .winning-number {
    border-radius: 50%;
    background-color: #fff;
    color: #20c997;
    width: 19vw;
    height: 19vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-d .content {
    border-radius: 1.2rem 1.2rem 0 0;
    background-color: #fff;
    margin-top: -1rem;
}

.two-d .action {
    width: 13vw !important;
    height: 12vw !important;
}

.ending-time {
    height: 6vh;
    width: 75vw;
    right: 0;
    top: 0;
}

.two-d .section {
    background-color: #F1F3FA;
}

.two-d .section.active {
    color: #fff;
    background-color: #20c997 !important;
}
</style>