<template>
    <div class="two-d-section">
        <div class="p-2 d-flex banner">
            <div class="col-6">
                <p class="fw-400 fs-12 mb-2">ထိုးမည့်အချိန်</p>
                <h1 class="fw-600 fs-16">{{ selectedSection.date }} <span class="fw-400 fs-12">({{
                    selectedSection.multiply }}ဆ)</span> </h1>
            </div>
            <div class="col-6 text-end">
                <p class="fw-400 fs-12 mb-2">ထီပိတ်ချိန်</p>
                <h1 class="fw-600 fs-16"><span>{{ countDown }} </span></h1>

            </div>
        </div>
        <div class="content  p-3">
            <div class="mb-2 d-flex align-items-center">
                <div :class="{ 'col-4': !searching, 'col-7': searching }">
                    <div v-if="searching" class="rounded-3 search-box d-flex py-2 px-3 align-items-center rounded-pill"
                        style="background-color: #F1F3FA;">
                        <font-awesome-icon icon="fa-solid fa-search me-3" size="sm" />
                        <input v-model="searchInput" type="text">
                    </div>
                    <OutlineButton v-else @click="initiateSearch()" class="rounded-pill">
                        <font-awesome-icon icon="fa-solid fa-search" size="sm" />
                        <span class="fw-600 fs-14 ms-2">ရိုက်ရှာမည်</span>
                    </OutlineButton>

                </div>
                <div class="text-custom d-flex align-items-center ms-auto" @click="showColorDescriptionModal = true">
                    <font-awesome-icon icon="fa-solid fa-circle-question" />
                    <span class="text-decoration-underline fw-400 fs-12">အရောင်</span>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="col-3">
                    <OutlineButton @click="round()" class="py-1">
                        <font-awesome-icon icon="fa-solid fa-retweet" size="sm" />
                        <span class="fw-600 fs-14 ms-2">R</span>
                    </OutlineButton>
                </div>
                <router-link :to="{ name: '2d' }" class="fw-600 fs-13 text-custom ms-auto text-decoration-none">
                    ထီပေါက်စဥ်မှတ်တမ်း
                </router-link>
            </div>
            <div class="numbers mt-3 d-flex flex-wrap">
                <div v-for="(value, index) in displayNumbers" @click="toggleNumber(value)" :key="index" class="number p-2">
                    <div :class="{ 'full': value.percent > 99.99, 'choosed': selectedNumbers.find(n => n.id == value.id) }"
                        class="fw-400 fs-16">
                        {{ value.number }}
                        <font-awesome-icon v-if="value.percent < 50" icon="fa-solid fa-circle" size="2xs"
                            class="position-absolute icon text-success" />
                        <font-awesome-icon v-else-if="(value.percent >= 50 && value.percent <= 90)"
                            icon="fa-solid fa-circle" size="2xs" class="position-absolute icon text-warning" />
                        <font-awesome-icon v-else-if="(value.percent > 90 && value.percent < 100)" icon="fa-solid fa-circle"
                            size="2xs" class="position-absolute icon text-danger" />
                    </div>
                </div>
            </div>
        </div>
        <div class="bet-amount-box py-3 px-3">
            <div class="d-flex align-items-center">
                <font-awesome-icon icon="fa-solid fa-money-bill" class="text-custom" size="sm" />
                <span class="fw-400 fs-12 mx-2">လက်ကျန်ငွေ</span>
                <span class="fw-600 fs-12 text-custom">{{ currentUser.balance ?
                    $helper.formattedNumber(currentUser.balance)
                    : 0 }} Unit</span>

                <span class="fw-400 fs-12 ms-auto text-custom">ငွေဖြည့်မည်</span>
            </div>
            <div class="d-flex mt-2">
                <div class="col-8  rounded-3 d-flex py-2 align-items-center" style="background-color: #F1F3FA;">
                    <span class="fw-400 text-custom fs-12 col-5 text-center">ထိုးမည့်ပမာဏ:</span>
                    <input v-model="betAmount" type="number" class="col-7">
                </div>
                <div class="col-4 ps-2">
                    <PrimaryButton @click="startBetting()">ထိုးမည်</PrimaryButton>
                </div>
            </div>
        </div>

        <ModalBox :show="showModalBox" @close="showModalBox = false">
            <template v-slot:header>ထိုးမည့်ဂဏန်းများ</template>
            <template v-slot:body>
                <div class="row mb-2">
                    <div class="col-3 text-center">
                        <span class="fw-600 fs-12">နံပါတ်</span>
                    </div>
                    <div class="col-3 text-center">
                        <span class="fw-600 fs-12">ဆ</span>
                    </div>
                    <div class="col-4 text-center">
                        <span class="fw-600 fs-12">ကြေး</span>
                    </div>
                </div>
                <div class="row align-items-center pb-3" v-for="(number, index) in selectedNumbers" :key="index">
                    <div class="col-3">
                        <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="number.number"
                            size="sm"></form-group>
                    </div>
                    <div class="col-3">
                        <form-group class="fw-400 fs-14" :readOnly="true" :modelValue="selectedSection.multiply"
                            size="sm"></form-group>
                    </div>
                    <div class="col-4">
                        <form-group class="fw-400 fs-14" type="number" v-model="number.amount" size="sm"></form-group>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                        <!-- <span style="width:7vw;aspect-ratio: 1/1;"
                            class="d-flex align-items-center justify-content-center bg-custom text-white rounded-circle me-2">
                            <font-awesome-icon icon="fa-solid fa-pencil" size="xs" />
                        </span> -->
                        <span @click="removeNumber(number)" style="width:7vw;aspect-ratio: 1/1;"
                            class="d-flex align-items-center justify-content-center bg-danger text-white rounded-circle me-2">
                            <font-awesome-icon icon="fa-solid fa-trash" size="xs" />
                        </span>
                    </div>
                </div>
                <div class="horizontal-line"></div>

                <div class="d-flex mt-2">
                    <div class="col-6 pe-1">
                        <SecondaryButton class="fw-700 fs-14" @click="showModalBox = false">နောက်မှ</SecondaryButton>
                    </div>
                    <div class="col-6 ps-1">
                        <PrimaryButton @click="bet()" class="fw-700 fs-14">
                            ဆက်သွားမည်</PrimaryButton>
                    </div>
                </div>
            </template>
        </ModalBox>

        <ModalBox :show="showColorDescriptionModal" @close="showColorDescriptionModal = false">
            <template v-slot:header>အရောင်သတ်မှတ်ချက်</template>
            <template v-slot:body>
                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-success" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၅၀% အောက်</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-warning" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၅၀% မှ ၉၀% ကြာ</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="fw-400 fs-16">
                            ??
                            <font-awesome-icon icon="fa-solid fa-circle" size="2xs"
                                class="position-absolute icon text-danger" />
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">၉၀% အထက်</span>
                </div>

                <div class="d-flex align-items-center">
                    <div class="number p-2">
                        <div class="full fw-400 fs-16">
                            ??
                        </div>
                    </div>
                    <span class="fw-400 fs-12 ms-2">ထိုးငွေပြည့်သွားပါပြီ</span>
                </div>

            </template>
        </ModalBox>

    </div>
</template>
<script>
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import ModalBox from '@/components/ModalComponent.vue';
import userService from '@/services/user.service';
import moment from 'moment';
export default {
    components: {
        ModalBox,
        PrimaryButton,
    },
    data() {
        return {
            selectedSection: {},
            showModalBox: false,
            searching: false,
            searchInput: null,
            showColorDescriptionModal: false,
            numbers: [
            ],
            selectedNumbers: [],
            logined: this.$store.state.auth.status.loggedIn,
            currentUser: this.$store.state.auth.user,
            countDown: null,
            betAmount: 0,
        }
    },
    mounted() {
        let selectedSection = this.$route.params.section
        if (selectedSection) {
            userService.get3DScheduleDetail(selectedSection).then(({ data }) => {
                console.log(data)
                this.numbers = data.data.numbers
                this.selectedSection = data.data
                this.startCountDown()
            })
        } else {
            this.$router.push({ name: '2d' })
        }
    },
    computed: {
        totalBetAmount() {
            return this.selectedNumbers.reduce((total, value) => {
                return total + parseInt(value.amount);
            }, 0);
        },

        displayNumbers() {
            if (this.searchInput) {
                return this.numbers.filter((number) => {
                    return number.number.includes(this.searchInput)
                })
            }
            return this.numbers;
        },
    },
    methods: {
        initiateSearch() {
            this.searching = true
        },
        startCountDown() {
            var that = this;
            let coundDownInterval = setInterval(function () {
                var eventTime = moment(new Date(moment().format("YYYY-MM-DD") + ' ' + that.selectedSection.end_time)); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
                var currentTime = moment(new Date());
                var diffTime = eventTime - currentTime;
                if (diffTime < 1) {
                    that.countDown = "0 : 0 : 0";
                    clearInterval(coundDownInterval)
                } else {
                    var duration = moment.duration(diffTime, 'milliseconds');
                    that.countDown = (duration.hours() + " : " + duration.minutes() + " : " + duration.seconds())
                }
            }, 1000);
        },
        toggleNumber(number) {
            if (number.percent > 99.99) {
                return;
            }

            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });

            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            } else {
                this.selectedNumbers.push({
                    id: number.id,
                    number: number.number,
                    amount: this.betAmount
                })
            }
        },
        removeNumber(number) {
            let index = this.selectedNumbers.findIndex(n => {
                return n.id == number.id
            });
            if (index != -1) {
                this.selectedNumbers.splice(index, 1)
            }
        },
        round() {
            this.selectedNumbers.forEach(selectedNumber => {
                let number = selectedNumber.number;
                let round_number = this.numbers.filter(n => {
                    n = n.number
                    console.log(number[1])
                    return (n.indexOf(number[0]) > -1) && (n.indexOf(number[1]) > -1) && (n.indexOf(number[2]) > -1);
                })

                round_number.forEach(value => {
                    let round_number_index = this.selectedNumbers.findIndex(n => {
                        return n.number == value.number
                    });
                    if (round_number_index == -1) {
                        this.selectedNumbers.push({
                            id: value.id,
                            number: value.number,
                            amount: selectedNumber.amount
                        })
                    }
                })


            })
        },
        startBetting() {
            this.selectedNumbers.forEach((number, index) => {
                this.selectedNumbers[index].amount = this.betAmount
            })
            this.showModalBox = true
        },
        bet() {
            this.$store.dispatch('loading')
            userService.bet3DSchedule(this.selectedSection.id, {
                bet_data: this.selectedNumbers
            }).then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                if (data.success) {
                    this.$store.dispatch('showToast', data.message)
                    this.$router.push({ name: 'home' })
                }
            }).catch(({ response }) => {
                if (response.status == 403) {
                    this.$store.dispatch('showError', response.data.message)
                }
            })
        }
    },
}

</script>
<style>
.two-d-section .banner {
    margin-top: -1px;
    color: #fff;
    background-color: #20c997;
    height: 9vh;
}

.two-d-section .content {
    height: 72vh;
    overflow: auto;
}

.two-d-section .number {
    width: 20%;
}

.number div {
    background-color: #F1F3FA;
    color: #000;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.number .icon {
    bottom: 5px;
    font-size: .4rem;
}

.number .full {
    background-color: transparent !important;
    color: #9e9e9e !important;
    border: 1px solid #F1F3FA;

}

.number .choosed {
    background-color: #20c997 !important;
    color: #fff !important;
}

.two-d-section .bet-amount-box {
    position: absolute;
    width: 100vw;
    bottom: 0;
    border-top: 1px solid #F1F3FA;
    height: 13vh;
    z-index: 1000;
}

.bet-amount-box input {
    border: none;
    outline: none;
    background-color: transparent;
}

.search-box input {
    border: none;
    outline: none;
    background-color: transparent;
}
</style>