<template>
    <div class="p-4">
        <div v-for="(transactions, index) in history" :key="index" class="mb-4">
            <p class="fw-400 mb-0 fs-14">{{ index }}</p>
            <ul class="list-group list-group-flush">

                <li class="list-group-item" v-for=" (transaction, index) in transactions" :key="index">
                    <router-link
                        :to="{ name: transaction.transaction_type != 'ဆုကြေးငွေ' ? 'transaction-detail' : null, params: { transaction_id: transaction.id } }"
                        class="py-2 text-decoration-none d-flex align-items-center text-dark">
                        <img v-if="transaction.type == '+'" src="@/assets/cash-in.png" style="width:6vw">
                        <img v-else src="@/assets/cash-out.png" style="width:6vw">
                        <div class="ms-2">
                            <h1 class="fw-700 fs-14 mb-1">
                                {{
                                    transaction.transaction_type
                                }}
                            </h1>
                            <span class="fw-400 fs-12" v-if="transaction.to"><span>To</span> {{ transaction.to }}</span>
                            <span class="fw-400 fs-12" v-else-if="transaction.title"> {{ transaction.title }}</span>

                        </div>
                        <div class="ms-auto">
                            <h1 class="fw-600 fs-14 mb-1">{{ transaction.type }} <span> {{
                                $helper.formattedNumber(transaction.amount)
                            }} </span>
                                Ks</h1>
                            <span class="fw-400 fs-12">{{ transaction.date_time }}</span>
                        </div>

                    </router-link>
                </li>

            </ul>
            <div class="transaction-line"></div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    data() {
        return {
            history: [
                // {
                //     month: "ဇန်န၀ါရီ",
                //     history: [
                //         {
                //             type: "-",
                //             to: "*******456",
                //             amount: "50000",
                //             date_time: "Today 16:56:18"
                //         },
                //         {
                //             type: "+",
                //             to: "*******456",
                //             amount: "40000",
                //             date_time: "Today 16:56:18"
                //         }
                //     ]
                // },
                // {
                //     month: "ဒီဇင်ဘာ",
                //     history: [
                //         {
                //             type: "-",
                //             to: "*******456",
                //             amount: "50000",
                //             date_time: "Today 16:56:18"
                //         },
                //         {
                //             type: "+",
                //             to: "*******456",
                //             amount: "40000",
                //             date_time: "Today 16:56:18"
                //         }
                //     ]
                // }
            ]
        }
    }
    ,
    mounted() {
        this.fetchHistory()
    },
    methods: {
        fetchHistory() {
            this.$store.dispatch('loading')
            userService.getTransactionHistory().then(({ data }) => {
                this.$store.dispatch('loadingComplete')
                this.history = data.data
            })
        }
    }
}
</script>
<style>
.transaction-line {
    border: 1px solid #EBF0FD;
}
</style>