<template>
    <div class="">
        <div class="winner-banner rounded-0 py-4">
            <div class="second-winner winner text-center">
                <div class="mb-2 winner-image" style="position: relative;">
                    <img src="@/assets/profile.svg" class="mx-auto" alt="">
                    <div class="winner-rank">
                        <span>2</span>
                    </div>
                </div>
                <h1 class="winner-name mb-0">Wutt Hmone</h1>
                <span class="winner-phone">09*******853</span>
            </div>

            <div class="first-winner winner text-center">
                <div class="mb-2 winner-image" style="position: relative;">
                    <img src="@/assets/profile.svg" class="mx-auto" alt="">
                    <div class="winner-rank">
                        <span>1</span>
                    </div>
                </div>
                <h1 class="winner-name mb-0">Wutt Hmone</h1>
                <span class="winner-phone">09*******853</span>
            </div>

            <div class="third-winner winner text-center">
                <div class="mb-2 winner-image" style="position: relative;">
                    <img src="@/assets/profile.svg" class="mx-auto" alt="">
                    <div class="winner-rank">
                        <span>3</span>
                    </div>
                </div>
                <h1 class="winner-name mb-0">Wutt Hmone</h1>
                <span class="winner-phone">09*******853</span>
            </div>
        </div>
        <div class="winners-action d-flex align-items-center px-3">
            <div>
                <font-awesome-icon icon="fa-solid fa-thumbs-up" size="lg" />
                <span class="ms-1">15</span>
            </div>
            <div class="ms-3">
                <font-awesome-icon icon="fa-solid fa-comment-dots" size="lg" />
                <span class="ms-1">15</span>
            </div>
        </div>
        <div class="winners p-4">
            <h1 class="title">Comments</h1>
            <ul id="comments" class="list-group list-group-flush">
                <li v-for="(comment, index) in comments" :key="index" class="list-group-item d-flex align-items-center">
                    <img class="img-fluid rounded-circle" :src="comment.customer.image">
                    <div style="flex-grow: 1;">
                        <h1 class="mb-1 label">{{ comment.customer.name }}</h1>
                        <div class="d-flex justify-content-between comment">
                            {{ comment.comment }}
                        </div>
                    </div>
                </li>
                <div class="d-flex justify-content-center mt-2 py-2 align-items-center">
                    <infinite-loading target="#app" @infinite="load"></infinite-loading>
                </div>
            </ul>
        </div>
        <div class="p-3 pb-4 w-100 comment-box d-flex align-items-center border-top position-fixed">
            <input type="text" v-model="comment" placeholder="Write a comment" class="">


            <div v-if="uploading" class="send-btn rounded-circle ms-2">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
            </div>

            <div v-else class="send-btn rounded-circle ms-2" @click="uploadComment()">
                <font-awesome-icon icon="fa-solid fa-paper-plane" />
            </div>

        </div>
    </div>
</template>
<style>
.winners-nav {
    background-color: #20c997;
    text-align: center;
    color: white;
    outline: none;
    border: none;
}

.winner-nav-item {
    padding: 10px 0px;
}

.winner-nav-item.active {
    background-color: #2B5DDC;
}

.winner {
    display: flex;
    flex-direction: column;
}

.winners {
    height: 58vh;
    overflow: auto;
}

.winner-rank span {
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    color: #2B5DDC;
    border-radius: 50%;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
}

.first-winner {
    justify-content: start;
    margin: 0px 20px;
}

.second-winner {
    justify-content: end;
}

.third-winner {
    justify-content: end;
}

.winner-banner {
    background: url('../assets/winner-banner-1.svg');
    height: 21vh;
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 1rem 1rem;
}

.winner-name {
    color: white;
    font-weight: 600;
    font-size: 12px;
}

.winner-phone {
    color: #CEDFFF;
    font-weight: 400;
    font-size: 12px;
}

.winner-amount .title,
.winners .title {
    font-weight: 700;
    font-size: 16px;
}

.winner-amount .comment {
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
}

.winner-amount .label,
.winners .label {
    font-weight: 600;
    font-size: 14px;
}

.winner-amount .bet-amount-label,
.winners .bet-amount-label {
    font-weight: 400;
    font-size: 11px;
}

.winner-amount .bet-amount,
.winners .bet-amount {
    font-weight: 600;
    font-size: 12px;
}

.winner-image img {
    width: 15vw;
    height: 15vw;
}

.winners .number {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.list-group-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.list-group-item img {
    width: 13vw;
    margin-right: .8rem;
}

.list-group-item .comment {
    font-weight: 400;
    font-size: 12px;
}

.comment-box {
    bottom: 0;
    height: 10vh;
    background-color: white;
}

.comment-box input {
    background: #EBF0FD;
    border: 0;
    border-radius: 2rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}

.comment-box .send-btn {
    background: #20c997;
    width: 3.1rem;
    aspect-ratio: 1/1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.winners-action {
    height: 5vh;
    background: #EBF0FD;
    color: #20c997;
}
</style>
<script>
import userService from '@/services/user.service';
import { ref } from 'vue';
export default {
    data() {
        return {
            uploading: false,
            comment: null,
            ended: false,
            errors: {
                comment: null
            },
        }
    },
    setup() {
        let page = 1;
        let comments = ref([])
        const load = async $state => {
            try {
                const data = await userService.getComments(page).then(({ data }) => data.data)
                comments.value.push(...data)
                if (data.length < 10) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
                page++;
            } catch (error) {
                $state.error();
            }
        };

        return {
            load,
            page,
            comments
        };
    },

    methods: {
        fetchComments() {
            this.page += 1;
            userService.getComments(this.page).then(({ data }) => {
                if (data.data.length < 10) {
                    this.ended = true
                }
                this.comments = data.data
            })
        },
        uploadComment() {
            this.uploading = true
            userService.uploadComment({
                comment: this.comment
            }).then(({ data }) => {
                this.uploading = false
                if (data.success) {
                    this.comments.unshift(data.data)
                    this.comment = null
                }
            }).catch(({ response }) => {
                console.log(response)
                this.uploading = false
                if (response.status == 422) {
                    this.errors = response.data.errors
                }
            })
        }
    }
}
</script>