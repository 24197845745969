<template>
    <div>

        <table class="table table-striped">
            <thead>
                <tr class="text-center">
                    <th>
                        နံပါတ်
                    </th>
                    <th>
                        ငွေ
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(num, index) in nums" :key="index" class="text-center">
                    <td>{{ num.number }}</td>
                    <td>{{ num.amount }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import userService from '@/services/user.service';

export default {
    mounted() {
        this.getLedger()
    },
    data() {
        return {
            nums: []
        }
    },
    methods: {
        getLedger() {
            userService.getLedger(this.$route.params.section).then(({ data }) => {
                this.nums = data.data
                console.log(this.nums)
            })
        }
    }
}
</script>