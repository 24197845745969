<template>
    <div>
        <SelectedWithdrawAmount></SelectedWithdrawAmount>
        <div v-if="agent" class="p-4 d-flex flex-column" style="height: calc(94vh - 51.5px);">
            <div class="">
                <div class="text-center">
                    <img width="62" height="62" class="rounded-circle mb-2" src="@/assets/profile.svg" alt="">
                    <div class="ms-2">
                        <h1 class="fw-600 fs-13 mb-0">{{ agent.name }}</h1>
                        <div>
                            <font-awesome-icon icon="fa-solid fa-star" class="text-warning"></font-awesome-icon>
                            <span class="fw-400 fs-13">80%</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/dollar-circle.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">ရောင်းမည့်ပမာဏ</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ $helper.formattedNumber(agent.minimum_amount) }} Unit to {{
                                    $helper.formattedNumber(agent.maximum_amount) }} Unit
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/wallet-money.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">Agent လက်ကျန်ငွေ</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                500,000 Unit
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/empty-wallet.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">ဖြည့်သွင်းနိုင်သည့် Pay အမျိုးအစား</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ agent.payment_methods.map(p => p.name).join(',') }}
                            </h1>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div class="col-2 text-center">
                            <img src="@/assets/clock.svg" alt="">
                        </div>
                        <div class="col-10">
                            <p class="fw-400 fs-13 mb-2">Unit ဖြည့်ကြာချိန်</p>
                            <h1 class="fw-600 fs-13 mb-0">
                                {{ agent.duration }} mins
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="horizontal-line my-3"></div>
            <div class="mb-3">
                <h1 class="fw-700 fs-14">Agent ဆီလွဲမည့် Pay အမျိုးအစားရွေးရန်</h1>
                <div class="d-flex">
                    <div @click="selectedPayment = payment.id" v-for="(payment, index) in agent.payment_methods"
                        :key="index" class="w-20 p-1">
                        <div class="border text-center rounded p-2" :class="{ 'active': payment.id == selectedPayment }">
                            <img class="payment_icon" style="width:100%;aspect-ratio: 1/1;" :src="payment.image" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="notice warning">
                <img src="@/assets/notice.svg" class="img-fluid me-2" alt="">
                <p class="mb-0 fw-400 fs-12">
                    Agent အကောင့်ဆီသို့ လွဲပြီးတာနှင့် သင့်ထံ ၅မိနစ်
                    အတွင်း ဖြည့်သွင်းသည့်Unit ပြန်လှည်ရောက်ရှိပါမည်။
                </p>
            </div>
            <div class="mt-auto">
                <PrimaryButton :disabled="selectedPayment == null" @click="goToConfirm()">Unit ဝယ်မည်</PrimaryButton>
            </div>
        </div>
    </div>
</template>
<script>
import SelectedWithdrawAmount from '@/components/SelectedWithdrawAmountComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        SelectedWithdrawAmount,
    },
    data() {
        return {
            payment_options: this.$store.state.cash.paymentMethods,
            selectedAgent: this.$route.params.agent,
            selectedPayment: null,
            agent: null
        }
    },
    mounted() {
        this.fetchAgent()
    },
    methods: {
        fetchAgent() {
            this.$store.dispatch('loading')
            userService.getWithdrawAgentInfo(this.selectedAgent).then(({ data }) => {
                console.log(data)
                this.$store.dispatch('loadingComplete')
                this.agent = data.data
            })
        },
        goToConfirm() {
            this.$router.push({
                name: 'withdraw.confirm', params: {
                    agent: this.selectedAgent,
                    payment: this.selectedPayment
                }
            })
        }
    }
}
</script>
<style scoped>
.active {
    background-color: #20c9971A !important;
    border: 1.7px solid #20c997 !important;

}
</style>