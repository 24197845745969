<template>
    <div>
        <div v-if="section" class="bg-success text-white text-center py-2">
            {{ section.type }} {{ section.opening_time }}
        </div>
        <div>
            <LedgerComponent></LedgerComponent>
        </div>
    </div>
</template>
<script>
import LedgerComponent from '@/components/LedgerComponent.vue';
import userService from '@/services/user.service'
export default {
    components: {
        LedgerComponent
    },
    data() {
        return {
            section: null
        }
    },
    mounted() {
        userService.get2DScheduleDetail(this.$route.params.section).then(({ data }) => {
            this.section = data.data
        })
    },
}
</script>