<template>
    <div class="p-4">
        <form class="d-flex flex-column h-100">

            <div>
                <h1 class="fw-700 fs-18">Unit ထုတ်မည်</h1>
                <p class="fw-400 fs-13">ထုတ်မည့် Unit ပမာဏရွေးချယ်ပါ</p>
            </div>

            <div class="mt-4">
                <h2 class="fw-600 fs-14 mb-3">Select amount</h2>
                <div class="row">
                    <div @click="selectAmount(amount)" v-for="(amount, key) in  amount_options" :key="key"
                        class="col-4 mb-2 px-1">
                        <div :class="{
                            'bg-success text-white': selectedAmount == amount,
                            'text-primary bg-amount': selectedAmount != amount
                        }" class="amount rounded text-center">
                            {{ $helper.formattedNumber(amount) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center my-2">
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
                <span class="fw-400 fs-14 mx-2">or</span>
                <span style="flex-grow:1;height: 1px;background-color: #EBF0FD;"></span>
            </div>

            <div class="mt-2">
                <form-group v-model="selectedAmount" label="Amount" placeholder="Enter amount"></form-group>
            </div>

            <div class="mt-3 d-flex align-items-center">
                <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် ငွေဖြည့်နည်း ကြည့်ပါ</span>
            </div>

            <div class="mt-auto d-flex">
                <div class="w-50 me-1">
                    <OrdinaryButton @click="back()" type="submit" class="login-btn mt-auto fw-700 fs-14">နောက်သို့
                    </OrdinaryButton>

                </div>
                <div class="w-50 ms-1">
                    <PrimaryButton @click="next()" type="submit" class="login-btn mt-auto fw-700 fs-14">
                        တင်ပြမည်
                    </PrimaryButton>
                </div>
            </div>
        </form>
    </div>
</template>
<script>

export default {
    data() {
        return {
            selectedAmount: this.$store.state.cash.selectedWithdrawAmount,
            amount_options: [
                5000, 10000, 50000, 100000, 2000000
            ]
        };
    },
    watch: {
        selectedAmount() {
            this.$store.dispatch('cash/chooseSelectedWithdrawAmount', this.selectedAmount)
        }
    },
    methods: {
        back() {
            this.$router.push({
                name: "withdraws"
            });
        },
        next() {
            if (this.selectedAmount > 0) {
                this.$router.push({
                    name: "withdraw.choose-agent"
                });
            }
        },
        selectAmount(amount) {
            this.selectedAmount = amount;
            console.log(this.selectedAmount)
        }
    },
}
</script>
<style>
.bg-amount {
    background: #EBF0FD;
}

.amount {
    padding: .8rem 0 !important;
}
</style>
