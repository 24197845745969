<template>
    <div class="p-4 d-flex flex-column" style="height: 94vh;">
        <div class="d-flex align-items-center my-2">
            <img :src="selectedPaymentInfo.image" style="width: 50px;" class="me-3">
            <h1 class="fw-700 fs-14 mb-0 text-decoration-none text-dark me-3">{{ selectedPaymentInfo.name }}</h1>
            <span class="fw-400 fs-14">နှင့် Unitထုတ်မည်</span>
        </div>
        <form-group :errors="errors.receiver_account_name" v-model="form.receiver_account_name"
            label="Receiver Account Name" placeholder="Enter a receiver account name"></form-group>
        <form-group :errors="errors.receiver_account_number" v-model="form.receiver_account_number"
            label="Receiver Account Number" placeholder="Enter a receiver account number"></form-group>
        <form-group v-model="form.receiver_account_number_confirmation" label="Confirm Receiver Account Number"
            placeholder="Re-enter a receiver account number"></form-group>

        <div class="mt-4">
            <h1 class="fw-600 fs-14">Checkout</h1>
            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">ဝယ်ယူမည့်ပမာဏ</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">{{ $helper.formattedNumber(selectedAmount) }}
                        Unit</span>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">ဘောက်ချာနံပါတ်</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">12345678</span>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-6">
                    <span class="fw-400 fs-13">Payအမျိုးအစား</span>
                </div>
                <div class="col-6 text-end">
                    <span class="fw-400 fs-14">{{ selectedPaymentInfo.name }}</span>
                </div>
            </div>

            <div class="notice warning">
                <img src="@/assets/notice.svg" class="img-fluid me-2" alt="">
                <p class="mb-0 fw-400 fs-12">
                    လွဲပြီးပါက ID နောက်ဆုံးဂဏန်း၆လုံးဖြည့်သွင်း
                    ပေးရန်လိုအပ်ပါသည်
                </p>
            </div>

            <div class="mt-3 d-flex align-items-center">
                <font-awesome-icon icon="fa-brands fa-youtube" class="me-2 text-primary" size="lg" />
                <span class="fw-400 fs-13 text-decoration-underline">အမှားအယွင်းမရှိစေရန် Unitဝယ်နည်း ကြည့်ပါ</span>
            </div>
        </div>
        <div class="mt-auto row">
            <div class="col-6">
                <SecondaryButton @click="$router.push({ name: 'withdraw.agent', agent: selectedAgent })">နောက်သို့
                </SecondaryButton>
            </div>
            <div class="col-6">
                <PrimaryButton @click="onSubmit()">
                    Unit ထုတ်မည်</PrimaryButton>
            </div>
        </div>
        <ConfirmationBox :show="showConfirmation" @cancel="showConfirmation = false" title="Confirmation" @ok="submit()"
            subtitle="ယူနစ်ထုတ်ယူရန် သေချာပါသလား။"></ConfirmationBox>
    </div>
</template>
<script>
// import Modal from '@/components/ModalComponent.vue';
import ConfirmationBox from '@/components/ConfirmationBoxComponent.vue';
import userService from '@/services/user.service';
export default {
    components: {
        ConfirmationBox
    },
    data() {
        return {
            step: 1,
            showWarningModal: true,
            showConfirmation: false,
            selectedAmount: this.$store.state.cash.selectedWithdrawAmount,
            selectedAgent: this.$route.params.agent,
            selectedPayment: this.$route.params.payment,
            form: {
                receiver_account_name: null,
                receiver_account_number: null,
                receiver_account_number_confirmation: null
            },
            errors: {

            },
        };
    },
    computed: {
        selectedPaymentInfo() {
            return this.$store.state.cash.paymentMethods.find(p => p.id == this.$route.params.payment)
        },
    },
    methods: {
        validate() {
            let isValid = true;
            if (this.form.receiver_account_name == null || this.form.receiver_account_name == "") {
                this.errors.receiver_account_name = ['Receiver account name is required'];
                isValid = false
            } else {
                this.errors.receiver_account_name = [];
            }

            if (this.form.receiver_account_number != this.form.receiver_account_number_confirmation) {
                this.errors.receiver_account_number = ['Receiver account number confirmation does not match'];
                isValid = false
            } else {
                this.errors.receiver_account_number = [];
            }

            if (this.form.receiver_account_number == null || this.form.receiver_account_number == "") {
                this.errors.receiver_account_number = ['Receiver account number is required'];
                isValid = false
            } else {
                this.errors.receiver_account_number = [];
            }

            return isValid;
        },
        onSubmit() {
            if (this.validate()) {
                console.log('here')
                this.showConfirmation = true
            }
        },
        submit() {
            var data = {
                receiver_account_name: this.form.receiver_account_name,
                receiver_account_phone: this.form.receiver_account_number,
                payment_method: this.selectedPayment,
                amount: this.selectedAmount
            }
            this.$store.dispatch('loading')
            userService.submitWithdraw(this.selectedAgent, data).then(() => {
                this.$store.dispatch('loadingComplete')
                this.$store.dispatch('showToast', 'Unit ထုတ်ခြင်းလုပ်ငန်းလုပ်ဆောင်နေသည်')
                this.$router.push({ name: 'home' })
            }).catch(({ response }) => {
                if (response.status == '403') {
                    this.$store.dispatch('showError', response.data.message)
                    this.$router.push({ name: 'home' })
                }
            })
        }
    }
}
</script>
<style>
.selected-payment {
    background-color: #20c9971A;
}

.bg-amount {
    background: #EBF0FD;
}

.amount-input {
    background: #EBF0FD;
    border: 0;
    border-radius: .7rem;
    width: 100%;
    padding: .8rem 1.2rem;
    font-size: .85rem;
}
</style>
