import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://localhost:8000/api/';
const API_URL = 'https://admin.kkk.smartcodemm.com/api/'
// const API_URL = 'https://admin.kkk.23pos.online/';

class UserService {

  getUserInfo() {
    return axios.get(API_URL + 'customer/info', { headers: authHeader() });
  }

  applyTopUp(data) {
    return axios.post(API_URL + 'dodeposit',data, { headers:authHeader() })
  }

  getTypes() {
    return axios.get(API_URL + 'type', { headers: authHeader() });
  }

  //2D
  get2DScheduleList() {
    return axios.get(API_URL + 'twod_section');
  }

  get2DScheduleDetail(id) {
    return axios.get(API_URL + 'twod_section/'+id)
  }

  getLedger(id) {
    return axios.get(API_URL + 'twod_section/'+id+'/ledger',{ headers:authHeader() })
  }

  get2DHotNums(id) {
    return axios.get(API_URL + 'twod_section/' + id + '/hot-nums',{headers:authHeader()});
  }
  
  getBlockNumbers(id) {
    return axios.get(API_URL + 'twod_section/' + id + '/block-nums',{headers:authHeader()});
  }

  bet2DSchedule(id,data) {
    return axios.post(API_URL + 'twod_section/'+id+'/bet', data, { headers:authHeader() })
  }

  getUser2DBettingHistory(start_date, end_date, section_id, price = null, name = null) {
    return axios.get(API_URL + 'twod/betting_log?start_date=' + start_date +'&end_date=' + end_date + '&section_id=' + section_id + '&price=' + price + '&name=' + name,  {headers:authHeader()})
  }

  getUser2DBettingHistoryDetail(date) {
    return axios.get(API_URL + 'twod/betting_log/' + date , {headers:authHeader()})
  }


  //3D
  bet3DSchedule(id,data) {
    return axios.post(API_URL + 'threed_section/'+id+'/bet', data, { headers:authHeader() })
  }

  get3DScheduleList() {
    return axios.get(API_URL + 'threed_section');
  }

  get3DScheduleHistory() {
    return axios.get(API_URL + 'threed_section/history');
  }

  get3DScheduleDetail(data) {
    return axios.get(API_URL + 'threed_section/'+data);
  }

  getUser3DBettingHistory() {
    return axios.get(API_URL + 'threed/betting_log', {headers:authHeader()})
  }

  getUser3DBettingHistoryDetail(date) {
    return axios.get(API_URL + 'threed/betting_log/'+date, {headers:authHeader()})
  }
  

  getTransactionDetail(id) {
    return axios.get(API_URL + 'transaction/' + id, { headers: authHeader() });
  }

  

  getNotifications() {
    return axios.get(API_URL + 'notification' , {headers: authHeader()});
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getAgents() {
    return axios.get(API_URL + 'agent-info/agent');
  }

  getClosingDays() {
    return axios.get(API_URL + 'closing-day');
  }

  getHomeBanner() {
    return axios.get(API_URL + 'slide/home');
  }

  getServices() {
    return axios.get(API_URL + 'service');
  }

  getServiceBanner() {
    return axios.get(API_URL + 'slide/service');
  }

  getWithdrawAgentList(amount) {
    return axios.get(API_URL + 'agent/withdraw/'+amount);
  }

  getWithdrawAgentInfo(id) {
    return axios.get(API_URL + 'agent/withdraw/'+id+'/info');
  }

  getPaymentMethods() {
    return axios.get(API_URL+ 'payment-method');
  }

  getDepositAgentList(amount) {
    return axios.get(API_URL + 'agent/deposit/'+amount);
  }

  getDepositAgentInfo(id) {
    return axios.get(API_URL + 'agent/deposit/'+id+'/info');
  }

  submitWithdraw(id,data) {
    return axios.post(API_URL + 'agent/withdaw/'+id+'/confirm',data,{ headers:authHeader() })
  }

  submitDeposit(id,data) {
    return axios.post(API_URL + 'agent/deposit/'+id+'/confirm',data,{ headers:authHeader() })
  }

  updateProfile(data) {
    return axios.post(API_URL+'profile/update',data,{ headers:authHeader() })
  }

  getTransactionHistory() {
    return axios.get(API_URL + 'transaction',{headers:authHeader()})
  }

  getComments(page) {
    return axios.get(API_URL + 'comment?page='+ page);
  }

  uploadComment(data) {
    return axios.post(API_URL + 'comment/store', data, { headers:authHeader() });
    
  }

  updateComment(id) {
    return axios.post(API_URL+'comment/'+id+'/update', { headers:authHeader() })
  }

  deleteComment(id) {
    return axios.post(API_URL+'comment/'+id+'/delete', { headers:authHeader() })
  }

  getOTP(data) {
    return axios.post(API_URL + 'otp/get',data);
  }

  getBalanceHistory(start_date,end_date) {
    return axios.get(API_URL + 'balance/history/'+start_date+'/'+end_date, {headers:authHeader()});
  }

  getProfitHistory(start_date,end_date) {
    return axios.get(API_URL + 'profit/history/'+start_date+'/'+end_date, {headers:authHeader()});
  }



}

export default new UserService();