import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import helper from './helpers/helper'
import store from "./store";
import helper2d from './helpers/2d';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas)
library.add(far)
library.add(fab)


import PrimaryButton from './components/buttons/PrimaryButton.vue';
import SecondaryButton from './components/buttons/SecondaryButton.vue';
import OrdinaryButton from './components/buttons/OrdinaryButton.vue';
import OutlineButton from './components/buttons/OutlineButton.vue';

import ReferrIcon from './components/ReferrIcon.vue';
import FormGroup from './components/FormGroup.vue';
import FooterNavbar from './components/FooterNavbar.vue';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

const app = createApp(App)
app.config.globalProperties.$helper = helper
app.config.globalProperties.$2d = helper2d 


app.component('font-awesome-icon', FontAwesomeIcon)
app.component('PrimaryButton', PrimaryButton)
app.component('OutlineButton', OutlineButton)
app.component('OrdinaryButton', OrdinaryButton)
app.component('SecondaryButton', SecondaryButton)

app.component('ReferrIcon', ReferrIcon)
app.component('FormGroup', FormGroup)
app.component('FooterNavbar',FooterNavbar)
app.component("infinite-loading", InfiniteLoading);

app.use(router)
app.use(store)
app.mount('#app')
