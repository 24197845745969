export default function Auth(router) {
    /**
     * If the user is already authenticated he shouldn't be able to visit 
     * pages like login, register, etc...
     */
    router.beforeEach((to, from, next) => {
        // localStorage.removeItem('user')
        let token = localStorage.getItem('user')
        /**
         * Checks if there's a token and the next page name is none of the following
         */
        // if (token && (to.name == 'login' || to.name == 'register' || to.name == 'index')) {
        //     router.push({name:'home'})
        // }


        console.log(token)
        if ((!token) && to.meta.requireAuth && to.name != 'login') {
            router.push({ name: 'login' })
        }

        next()
    })
}